import Http from "@/config";
import axios from "axios";
// eslint-disable-next-line

export const accept = (params) => Http.post(`/partners/shellbox`, params);
export const getUser = (id) => Http.get(`/partners/shellbox/${id}`);
export const convertPublicId = (id) =>
  axios.get(
    `https://qfnks6p8c3.execute-api.us-east-1.amazonaws.com/driver/${id}`
  );
